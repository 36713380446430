import * as actionTypes from './actions';
import config from './../config';

const initialState = {
  isOpen: [], //for active default menu
  isTrigger: [], //for active default menu, set blank for horizontal
  ...config,
  isFullScreen: false, // static can't change
  isAuthorised: false,
  adminName: '',
  adminId: '',
  stateEnglish: '',
  districtEnglish: '',
  talukaEnglish: '',
  cityEnglish: '',
  pincode: '',

};

const reducer = (state = initialState, action) => {
  let trigger = [];
  let open = [];

  switch (action.type) {
    case actionTypes.CHANGE_ADMIN_REGION:
      return {
        ...state,
        adminRegion: action.payload
      }
    case actionTypes.ADMIN_AUTHORIZED:
      return {
        ...state,
        isAuthorised: action.payload.authState,
        adminName: action.payload.adminUsername,
        adminId: action.payload.adminId,
        pincode: action.payload.adminPincode,
        cityEnglish: action.payload.adminCity,
        talukaEnglish: action.payload.adminTaluka,
        districtEnglish: action.payload.adminDistrict,
        stateEnglish: action.payload.adminState,

      }
    case actionTypes.TAB_KEY_SET:
      return {
        ...state,
        activeTabKey: action.payload
      }
    case actionTypes.FRANCHISEE_TAB_KEY_SET:
      return {
        ...state,
        franchiseeTabKey: action.payload
      }

    case actionTypes.FRANCHISEE_DOCUMENTIMAGE_TAB_KEY_SET:
      return {
        ...state,
        franchiseeDocumentImageTabKey: action.payload
      }
    case actionTypes.DELIVERYSTAFFSETTLEMENT_TAB_KEY_SET:
      return {
        ...state,
        DeliveryStaffSettlementTabKey: action.payload
      }
    case actionTypes.BANNERMANAGMENT_TAB_KEY_SET:
      return {
        ...state,
        BannerManagmentTabKey: action.payload
      }
    case actionTypes.PREBANNERMANAGMENT_TAB_KEY_SET:
      return {
        ...state,
        PreBannerManagmentTabKey: action.payload

      }
    case actionTypes.CUSTOMER_TAB_KEY_SET:
      return {
        ...state,
        customerTabKey: action.payload
      }
    case actionTypes.DELIVERY_TAB_KEY_SET:
      return {
        ...state,
        deliveryTabKey: action.payload
      }
    case actionTypes.RESTAURANT_TAB_KEY_SET:
      return {
        ...state,
        restaureantTabKey: action.payload
      }
    case actionTypes.RESTAURANT_IMAGEPDF_TAB_KEY_SET:
      return {
        ...state,
        restaureantImagePdfTabKey: action.payload
      }
    case actionTypes.PRERESTAURANT_TAB_KEY_SET:
      return {
        ...state,
        prerestaureantTabKey: action.payload
      }
    case actionTypes.PRERESTAURANT_IMAGEPDF_TAB_KEY_SET:
      return {
        ...state,
        prerestaureantImagePdfTabKey: action.payload
      }
    case actionTypes.COLLAPSE_MENU:
      return {
        ...state,
        collapseMenu: !state.collapseMenu
      };
    case actionTypes.COLLAPSE_TOGGLE:
      if (action.menu.type === 'sub') {
        open = state.isOpen;
        trigger = state.isTrigger;

        const triggerIndex = trigger.indexOf(action.menu.id);
        if (triggerIndex > -1) {
          open = open.filter(item => item !== action.menu.id);
          trigger = trigger.filter(item => item !== action.menu.id);
        }

        if (triggerIndex === -1) {
          open = [...open, action.menu.id];
          trigger = [...trigger, action.menu.id];
        }
      } else {
        open = state.isOpen;
        const triggerIndex = (state.isTrigger).indexOf(action.menu.id);
        trigger = (triggerIndex === -1) ? [action.menu.id] : [];
        open = (triggerIndex === -1) ? [action.menu.id] : [];
      }

      return {
        ...state,
        isOpen: open,
        isTrigger: trigger
      };
    case actionTypes.NAV_CONTENT_LEAVE:
      return {
        ...state,
        isOpen: open,
        isTrigger: trigger,
      };
    case actionTypes.NAV_COLLAPSE_LEAVE:
      if (action.menu.type === 'sub') {
        open = state.isOpen;
        trigger = state.isTrigger;

        const triggerIndex = trigger.indexOf(action.menu.id);
        if (triggerIndex > -1) {
          open = open.filter(item => item !== action.menu.id);
          trigger = trigger.filter(item => item !== action.menu.id);
        }
        return {
          ...state,
          isOpen: open,
          isTrigger: trigger,
        };
      }
      return { ...state };
    case actionTypes.FULL_SCREEN:
      return {
        ...state,
        isFullScreen: !state.isFullScreen
      };
    case actionTypes.FULL_SCREEN_EXIT:
      return {
        ...state,
        isFullScreen: false
      };
    case actionTypes.CHANGE_LAYOUT:
      return {
        ...state,
        layout: action.layout
      };
    default:
      return state;
  }
};

export default reducer;