
export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const CHANGE_ADMIN_REGION = 'CHANGE_ADMIN_REGION';
export const ADMIN_AUTHORIZED = 'ADMIN_AUTHORIZED';
export const TAB_KEY_SET = 'TAB_KEY_SET';
export const FRANCHISEE_TAB_KEY_SET = 'FRANCHISEE_TAB_KEY_SET';
export const FRANCHISEE_DOCUMENTIMAGE_TAB_KEY_SET = 'FRANCHISEE_DOCUMENTIMAGE_TAB_KEY_SET';
export const DELIVERYSTAFFSETTLEMENT_TAB_KEY_SET = 'DELIVERYSTAFFSETTLEMENT_TAB_KEY_SET';
export const BANNERMANAGMENT_TAB_KEY_SET = 'BANNERMANAGMENT_TAB_KEY_SET';
export const PREBANNERMANAGMENT_TAB_KEY_SET = 'PREBANNERMANAGMENT_TAB_KEY_SET';
export const CUSTOMER_TAB_KEY_SET = 'CUSTOMER_TAB_KEY_SET';
export const DELIVERY_TAB_KEY_SET = 'DELIVERY_TAB_KEY_SET';
export const RESTAURANT_TAB_KEY_SET = 'RESTAURANT_TAB_KEY_SET';
export const RESTAURANT_IMAGEPDF_TAB_KEY_SET = 'RESTAURANT_IMAGEPDF_TAB_KEY_SET';
export const PRERESTAURANT_TAB_KEY_SET = 'PRERESTAURANT_TAB_KEY_SET';
export const PRERESTAURANT_IMAGEPDF_TAB_KEY_SET = 'PRERESTAURANT_IMAGEPDF_TAB_KEY_SET';