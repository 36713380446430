import React from 'react';

const ForgotPassword = React.lazy(() => import('./Pages/Authentication/ForgotPassword/ForgotPassword'));
const SignIn = React.lazy(() => import('./Pages/Authentication/SignIn/SignIn'));

const route = [
  { path: '/auth/forgotpassword', exact: true, name: 'Forgot Password', component: ForgotPassword },
  { path: '/auth/signin', exact: true, name: 'Signin', component: SignIn }
];

export default route;